
export function tariffeType(val, props) {
    if (val === 1)  {
        return props.t("_INDIVIDUAL_ACCOUNT_")
    }
    else  if (val === 2)  {
        return props.t("_ORGANIZATION_ACCOUNT_")
    }
    else  if (val === 3)  {
        return props.t("_PERSONAL_PARCELS_")
    } else {
      return  val
    }
}

export function BranchAddress(val, props) {
    if (val === 'didube')  {
        return props.t("_Branch_short_Address_1")
    }
    else  if (val === 'gldani')  {
        return props.t("_Branch_short_Address_2")
    }
    else  if (val === 'isani')  {
        return props.t("_Branch_short_Address_3")
    }
    else  if (val === 'kutaisi_1')  {
        return props.t("_Branch_short_Address_4")
    }
    else {
      return  val
    }
}

export function shopsTypes(val ) {
    const shops =  ['TAOBAO.COM', 'EBAY.COM','amainhobbies.com', 'ALIEXPRESS.COM', 'ALIBABA.COM', "TMALL.COM", "trendyol.com", "hepsiburada.com"," fashfed.com", "amazon.com.tr", "other" ]
    if (shops.includes(val)){
        return true
    }else {
        return false
    }
}
